<template>
	<div data-route data-fullscreen>
		<activity
			:lessonId="getLessonId"
			:activityId="getActivityId"
			:courseId="getCourseId"
			:entryRoute="'teaching'"
			:isLesson="true"
			:isLiveLesson="true"
		/>
	</div>
</template>

<script>

	import Activity     from '@/components/activities/view/Activity';
	import routeParams  from '@/mixins/routeParams';

	export default {
		layout: 'activity',
		metaInfo: {
			title: 'Activity view'
		},
		components: {
			Activity
		},
		mixins: [routeParams],
		computed: {
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getCourseRoute ||
						!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
			},
			getActivitiesRoute () {
				if (!this.getLessonRoute) {
					return false;
				}
				return `${this.getLessonRoute}/activities`;
			},
			getActivityRoute () {
				if (!this.getActivitiesRoute ||
						!this.getActivityId) {
					return false;
				}
				return `${this.getActivitiesRoute}/${this.getActivityId}`;
			}
		},
		created () {}
	};

</script>

<style lang="scss" scoped>

	[data-route] {
		[data-component='activity'] {
		}
	}

</style>
